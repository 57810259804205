<template>
  <b-card bg-variant="primary" class="text-white">
    <b-card-header class="p-0">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <feather-icon size="24" class="mr-1" icon="BookOpenIcon" />
          <b-card-title class="ml-25 text-white">
            Auftrag #{{ orderData.Auftragsnummer }}
          </b-card-title>
        </div>
        <b-button
          variant="success"
          @click="
            $router.push({
              name: 'auftrag-bearbeiten',
              params: { id: $route.params.id },
            })
          "
        >
          <span class="text-nowrap"> Auftrag bearbeiten </span>
        </b-button>
      </div>
    </b-card-header>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BButton } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
};
</script>
