<template>
  <b-card
    :bg-variant="orderData.Auftragsbestaetigung_JSON ? 'success' : 'secondary'"
    class="text-white"
  >
    <b-card-header class="p-0">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <feather-icon size="24" class="mr-1" icon="BookIcon" />
          <b-card-title class="ml-25 text-white">
            Auftragsbestätigung
          </b-card-title>
        </div>
        <b-button
          variant="primary"
          @click="
            $router.push({
              name: 'auftragsbestaetigung',
              params: { id: $route.params.id },
            })
          "
        >
          <span class="text-nowrap">
            Auftragsbestätigung
            {{
              orderData.Auftragsbestaetigung_JSON
                ? 'ansehen/bearbeiten'
                : 'erstellen'
            }}
          </span>
        </b-button>
      </div>
    </b-card-header>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BButton } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
};
</script>
