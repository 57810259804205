<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === undefined">
      <h4 class="alert-heading">Fehler bei der Abfrage der Daten</h4>
      <div class="alert-body">
        Es wurde kein Auftrag mit dieser ID gefunden. Gehen Sie bitte zurück zur
        <b-link class="alert-link" :to="{ name: 'auftraege' }">
          Auftragsliste
        </b-link>
        und wählen Sie einen anderen Auftrag aus.
      </div>
    </b-alert>

    <template v-if="orderData">
      <!-- First Row -->
      <b-row>
        <b-col>
          <auftrag-bearbeiten-button :orderData="orderData" />
        </b-col>
        <b-col>
          <auftragsbestaetigung-card :orderData="orderData" />
        </b-col>
        <b-col cols="12">
          <tagesberichte-table />
        </b-col>
        <b-col cols="12">
          <invoice-table />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
import TagesberichteTable from '@/layouts/components/tables/TagesberichteTable.vue';
import InvoiceTable from '@/layouts/components/tables/InvoiceTable.vue';
import AuftragsbestaetigungCard from '@/layouts/components/widgets/AuftragsbestaetigungCard.vue';
import AuftragBearbeitenButton from '@/layouts/components/widgets/AuftragBearbeitenButton.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    TagesberichteTable,
    InvoiceTable,
    AuftragsbestaetigungCard,
    AuftragBearbeitenButton,
  },
  data() {
    return {
      orderData: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Page Title
    to.meta.pageTitle = `Auftrag Details`;
    next();
  },
  async created() {
    const qs = require('qs');
    const query = qs.stringify(
      {
        populate: '*',
      },
      {
        encodeValuesOnly: true,
        indices: false,
      }
    );
    await this.$http
      .get(`/auftraege/${this.$route.params.id}?${query}`)
      .then(response => {
        this.orderData = response.data.data;
      })
      .catch(err => console.log(err));

    // Change Page Title
    this.$route.meta.pageTitle = `Auftrag #${this.orderData.Auftragsnummer} Details`;
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });
  },
};
</script>

<style></style>
