<template>
  <b-card title="Rechnungen" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="FileTextIcon" />
        <b-card-title class="ml-25"> Rechnungen </b-card-title>
      </div>
    </b-card-header>
    <div class="mx-2 mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mb-0">Zeige</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label class="mb-0">Rechnungen</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="8">
          <div class="d-flex align-items-center justify-content-end">
            <label class="mb-0 mr-50 text-nowrap" for="status-selection"
              >Status filtern:</label
            >
            <v-select
              id="status-selection"
              v-model="filters.statusFilter"
              label="title"
              :options="[
                { title: 'Entwurf', value: 'Entwurf' },
                { title: 'Offen', value: 'Offen' },
                { title: 'Bezahlt', value: 'Bezahlt' },
                { title: 'Überfällig', value: 'Ueberfaellig' },
                { title: 'Storniert', value: 'Storniert' },
              ]"
            />
            <b-form-input
              v-model="filters.searchQuery"
              class="d-inline-block mx-1"
              placeholder="Suchen..."
            />
            <b-button
              variant="primary"
              @click="
                $router.push(
                  generalInvoiceTable
                    ? {
                        name: 'manuelle-rechnung-erstellen',
                        params: { manualInvoice: true },
                      }
                    : {
                        name: 'rechnung-erstellen',
                        params: { id: $route.params.id, manualInvoice: false },
                      }
                )
              "
            >
              <span class="text-nowrap">{{
                generalInvoiceTable
                  ? 'Rechnung manuell hinzufügen'
                  : 'Rechnung hinzufügen'
              }}</span>
            </b-button>
            <b-button
              class="ml-1"
              v-if="generalInvoiceTable"
              variant="primary"
              @click="
                $router.push({
                  name: 'manuelle-rechnung-erstellen',
                  params: { manualInvoice: true, gutschrift: true },
                })
              "
            >
              <span class="text-nowrap">{{ 'Gutschrift hinzufügen' }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        :key="tableKey"
        :items="invoiceItemsProvider"
        :fields="fields"
        :per-page="0"
        :current-page="currentPage"
        :filter="filters"
        sort-by="Rechnungsnummer"
        striped
        responsive
        class="mb-0"
      >
        <!-- Column: Actions -->
        <template #cell(Aktionen)="row">
          <div class="text-nowrap">
            <!-- 
            <feather-icon
              :id="`order-row-${row.item.id}-preview-icon`"
              icon="EyeIcon"
              size="20"
              class="mr-50 cursor-pointer text-primary"
              @click="row.toggleDetails"
            />
            <b-tooltip
              title="Infos einblenden"
              class="cursor-pointer"
              :target="`order-row-${row.item.id}-preview-icon`"
            />
            -->
            <feather-icon
              :id="`order-row-${row.item.id}-details-icon`"
              icon="FileTextIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push(
                  row.item.Manuelle_Rechnung
                    ? {
                        name: 'manuelle-rechnung-vorschau',
                        params: { id: row.item.id },
                      }
                    : {
                        name: 'rechnung-vorschau',
                        params: { id: row.item.id },
                      }
                )
              "
            />
            <b-tooltip
              title="Vorschau"
              class="cursor-pointer"
              placement="left"
              :target="`order-row-${row.item.id}-details-icon`"
            />

            <feather-icon
              v-if="row.item.Status !== 'Entwurf'"
              :id="`order-row-${row.item.id}-edit-icon`"
              icon="Edit2Icon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
            />
            <b-popover
              v-if="row.item.Status !== 'Entwurf'"
              :target="`order-row-${row.item.id}-edit-icon`"
              triggers="click"
              placement="left"
            >
              <template #title>
                <span>Status anpassen</span>
              </template>
              <v-select
                class="mb-1"
                :id="`status-aendern-${row.item.id}`"
                :name="`status-aendern-${row.item.id}`"
                v-model="row.item.Status"
                :options="[
                  { Bezeichnung: 'Offen', value: 'Offen' },
                  { Bezeichnung: 'Bezahlt', value: 'Bezahlt' },
                  { Bezeichnung: 'Überfällig', value: 'Ueberfaellig' },
                  { Bezeichnung: 'Storniert', value: 'Storniert' },
                ]"
                label="Bezeichnung"
                :clearable="false"
              />
              <b-button
                size="sm"
                class="mr-25"
                variant="primary"
                @click="updateStatus(row.item.id, row.item.Status)"
              >
                Speichern
              </b-button>
              <b-button
                size="sm"
                class="ml-25"
                variant="danger"
                @click="$root.$emit('bv::hide::popover')"
              >
                Abbrechen
              </b-button>
            </b-popover>
            <b-tooltip
              v-if="row.item.Status !== 'Entwurf'"
              title="Status ändern"
              class="cursor-pointer"
              placement="right"
              :target="`order-row-${row.item.id}-edit-icon`"
            />
            <feather-icon
              v-if="row.item.Status === 'Entwurf'"
              :id="`order-row-${row.item.id}-edit-invoice-icon`"
              icon="EditIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push(
                  row.item.Manuelle_Rechnung
                    ? {
                        name: 'manuelle-rechnung-bearbeiten',
                        params: { id: row.item.id },
                      }
                    : {
                        name: 'rechnung-bearbeiten',
                        params: { id: row.item.id },
                      }
                )
              "
            />
            <b-tooltip
              v-if="row.item.Status === 'Entwurf'"
              title="Bearbeiten"
              class="cursor-pointer"
              placement="left"
              :target="`order-row-${row.item.id}-edit-invoice-icon`"
            />
          </div>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card class="details-card">
            <h4 class="mb-1">Allgemein</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Bestellnummer: </strong>#{{ row.item.Bestellnummer }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Startdatum: </strong
                >{{
                  new Date(
                    row.item.Auftragskonditionen.Startdatum
                  ).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="row.item.Auftragskonditionen.Startzeit"
              >
                <strong>Startzeit: </strong
                >{{ row.item.Auftragskonditionen.Startzeit.substr(0, 5) }}
                Uhr
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="
                  row.item.Auftragskonditionen.Enddatum >
                  row.item.Auftragskonditionen.Startdatum
                "
              >
                <strong>Enddatum: </strong
                >{{
                  new Date(
                    row.item.Auftragskonditionen.Enddatum
                  ).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Mitarbeiterbedarf: </strong
                >{{ row.item.Auftragskonditionen.Mitarbeiterbedarf }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Kunde</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Firma: </strong>{{ row.item.Kunde.Firma }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Telefon: </strong>{{ row.item.Kunde.Kontakt.Telefon }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Fax: </strong>{{ row.item.Kunde.Kontakt.Fax }}
              </b-col>
            </b-row>

            <!-- Ansprechpartner -->
            <b-row class="mx-0">
              <b-col md="12" class="mb-1">
                <b-card
                  class="mb-0 details-card"
                  v-for="ansprechpartner in getContactPerson(row.item)"
                  :key="ansprechpartner.id"
                >
                  <h5 class="mb-1"><b>Ansprechpartner</b></h5>
                  <b-row class="mx-1">
                    <b-col cols="4" class="mb-1">
                      <strong>Name: </strong>{{ ansprechpartner.Vorname }}
                      {{ ansprechpartner.Nachname }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Abteilung: </strong
                      >{{ ansprechpartner.Abteilung }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Position: </strong>{{ ansprechpartner.Position }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Email: </strong
                      ><a
                        :href="`mailto:${ansprechpartner.Email}`"
                        target="_blank"
                        >{{ ansprechpartner.Email }}</a
                      >
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Telefon: </strong
                      ><a :href="`tel:${ansprechpartner.Telefon}`">{{
                        ansprechpartner.Telefon
                      }}</a>
                    </b-col>
                    <b-col cols="4">
                      <strong>Mobil: </strong
                      ><a :href="`tel:${ansprechpartner.Mobil}`">{{
                        ansprechpartner.Mobil
                      }}</a>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

            <!-- Auftragsort -->
            <b-row class="mx-0">
              <b-col md="12" class="mb-2">
                <b-card class="mb-0 details-card">
                  <h5 class="mb-1"><b>Auftragsort</b></h5>
                  <b-row class="align-items-center mx-1">
                    <b-col
                      cols="4"
                      class="mb-1"
                      v-if="row.item.Abweichender_Aufragsort"
                    >
                      <div class="mb-1">
                        <strong>Straße: </strong>
                        {{ row.item.Abweichender_Aufragsort.Strasse }}
                        {{ row.item.Abweichender_Aufragsort.Hausnummer }}
                      </div>
                      <div
                        class="mb-1"
                        v-if="row.item.Abweichender_Aufragsort.Zusatz"
                      >
                        <strong>Adresszusatz: </strong>
                        {{ row.item.Abweichender_Aufragsort.Zusatz }}
                      </div>
                      <div class="mb-1">
                        <strong>PLZ: </strong>
                        {{ row.item.Abweichender_Aufragsort.PLZ }}
                      </div>
                      <div>
                        <strong>Stadt: </strong>
                        {{ row.item.Abweichender_Aufragsort.Stadt }}
                      </div>
                    </b-col>
                    <b-col cols="4" class="mb-1" v-else>
                      <div class="mb-1">
                        <strong>Straße: </strong>
                        {{ row.item.Kunde.Anschrift.Strasse }}
                        {{ row.item.Kunde.Anschrift.Hausnummer }}
                      </div>
                      <div class="mb-1" v-if="row.item.Kunde.Anschrift.Zusatz">
                        <strong>Adresszusatz: </strong>
                        {{ row.item.Kunde.Anschrift.Zusatz }}
                      </div>
                      <div class="mb-1">
                        <strong>PLZ: </strong>
                        {{ row.item.Kunde.Anschrift.PLZ }}
                      </div>
                      <div>
                        <strong>Stadt: </strong>
                        {{ row.item.Kunde.Anschrift.Stadt }}
                      </div>
                    </b-col>
                    <b-col
                      cols="8"
                      class="mb-1"
                      v-if="row.item.Abweichender_Aufragsort"
                    >
                      <iframe
                        class="w-100 google-map"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="`https://maps.google.com/maps?&amp;q=${encodeURIComponent(
                          `${row.item.Abweichender_Aufragsort.Strasse} ${row.item.Abweichender_Aufragsort.Hausnummer}, ${row.item.Abweichender_Aufragsort.PLZ} ${row.item.Abweichender_Aufragsort.Stadt}`
                        )}&amp;output=embed`"
                      ></iframe>
                    </b-col>
                    <b-col cols="8" class="mb-1" v-else>
                      <iframe
                        class="w-100 google-map"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="`https://maps.google.com/maps?&amp;q=${encodeURIComponent(
                          `${row.item.Kunde.Anschrift.Strasse} ${row.item.Kunde.Anschrift.Hausnummer}, ${row.item.Kunde.Anschrift.PLZ} ${row.item.Kunde.Anschrift.Stadt}`
                        )}&amp;output=embed`"
                      ></iframe>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Prüfpositionen</h4>
            <b-row class="mx-0">
              <b-col md="12" class="mb-1">
                <b-card
                  class="mb-1 details-card"
                  v-for="(pruefposition, index) in row.item.Pruefpositionen"
                  :key="pruefposition.id"
                >
                  <h5 class="mb-1">
                    <b>Pruefposition {{ index + 1 }}</b>
                  </h5>
                  <b-row class="align-items-center mx-1">
                    <b-col cols="4" class="mb-1">
                      <strong>Beschreibung: </strong
                      >{{ pruefposition.Bezeichnung }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Bauteilgruppe: </strong
                      >{{ pruefposition.Bauteilgruppe }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Stückzahl: </strong>{{ pruefposition.Stueckzahl }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Status: </strong>
                      <b-badge
                        :variant="
                          status[1][invoiceStatus(pruefposition.Status)]
                        "
                      >
                        {{ status[0][invoiceStatus(pruefposition.Status)] }}
                      </b-badge>
                    </b-col>
                    <b-col
                      cols="4"
                      class="mb-1"
                      v-if="pruefposition.Folgelieferung_Pruefen"
                    >
                      <strong>Folgelieferung Prüfen: </strong>
                      <b-form-checkbox
                        class="d-inline-block"
                        :checked="pruefposition.Folgelieferung_Pruefen"
                        disabled
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Mitarbeiter</h4>
            <b-row class="mx-0">
              <b-col
                md="4"
                class="mb-1"
                v-for="mitarbeiter in row.item.Mitarbeiter"
                :key="mitarbeiter.id"
              >
                <b-card class="details-card">
                  <b-media vertical-align="center" class="align-items-center">
                    <template #aside>
                      <b-avatar
                        size="100"
                        :src="
                          mitarbeiter.User.avatar
                            ? $dbBaseUrl +
                              mitarbeiter.User.avatar.formats.thumbnail.url
                            : ''
                        "
                        :text="`${mitarbeiter.Vorname.charAt()}${mitarbeiter.Nachname.charAt()}`"
                        :variant="`light-primary`"
                        :to="{
                          name: 'mitarbeiter-ansicht',
                          params: { id: mitarbeiter.id },
                        }"
                      />
                    </template>
                    <b-link
                      :to="{
                        name: 'mitarbeiter-ansicht',
                        params: { id: mitarbeiter.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ `${mitarbeiter.Vorname} ${mitarbeiter.Nachname}` }}
                    </b-link>
                    <small class="text-muted">{{
                      mitarbeiter.User.email
                    }}</small>
                  </b-media>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <div v-if="row.item.Reporting">
              <h4 class="mb-1">Reporting/Dokumentation</h4>
              <b-row class="mx-1">
                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Format_Email"
                >
                  <strong>Reports als Email: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Format_Email"
                    disabled
                  />
                </b-col>
                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Format_Fax">
                  <strong>Reports als Fax: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Format_Fax"
                    disabled
                  />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Format_Telefon"
                >
                  <strong>Reports per Telefon: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Format_Telefon"
                    disabled
                  />
                </b-col>

                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Tagesbericht"
                >
                  <strong>Tagesbericht: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Tagesbericht"
                    disabled
                  />
                </b-col>
                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Auswertung">
                  <strong>Auswertung: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Auswertung"
                    disabled
                  />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Foto_Filmdokumentation"
                >
                  <strong>Foto- / Filmdokumentation: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Foto_Filmdokumentation"
                    disabled
                  />
                </b-col>

                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Frequenz">
                  <strong>Reportingfrequenz: </strong>
                  {{ reportingFrequency(row.item.Reporting.Frequenz) }}
                </b-col>

                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Empfaenger">
                  <strong>Reports senden an: </strong>
                  <a
                    :href="`mailto:${row.item.Reporting.Empfaenger}`"
                    v-if="row.item.Reporting.Empfaenger.includes('@')"
                    >{{ row.item.Reporting.Empfaenger }}</a
                  >
                  <p v-else>{{ row.item.Reporting.Empfaenger }}</p>
                </b-col>
              </b-row>
              <hr class="mt-0 mb-2" />
            </div>

            <h4 class="mb-1">Weitere Auftragsdaten</h4>
            <b-row class="mx-1">
              <b-col
                md="4"
                class="mb-1"
                v-if="row.item.Auftrag_Bei_Pruefanweisung"
              >
                <strong>Auftrag liegt bei Prüfanweisung: </strong>
                <b-form-checkbox
                  class="d-inline-block"
                  :checked="row.item.Auftrag_Bei_Pruefanweisung"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Auftrag_Bei_Muster">
                <strong>Auftrag liegt bei Muster: </strong>
                <b-form-checkbox
                  class="d-inline-block"
                  :checked="row.item.Auftrag_Bei_Muster"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Auftrag_Bei_Sonstige">
                <strong>Auftrag liegt bei: </strong
                >{{ row.item.Auftrag_Bei_Sonstige }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Details ausblenden
            </b-button>
          </b-card>
        </template>

        <!-- Column: Order Rechnungsnummer -->
        <template #cell(Rechnungsnummer)="data">
          <b-link
            :to="
              data.item.Manuelle_Rechnung
                ? {
                    name: 'manuelle-rechnung-vorschau',
                    params: { id: data.item.id },
                  }
                : {
                    name: 'rechnung-vorschau',
                    params: { id: data.item.id },
                  }
            "
            >#{{ data.item.Rechnungsnummer }}</b-link
          >
        </template>

        <!-- Column: Kunde -->
        <template #cell(Kunde)="data">
          {{
            data.item.Manuelle_Rechnung
              ? data.item.Manueller_Kunde.Kunde.Firma
              : data.item.Auftrag.Kunde.Firma
          }}
        </template>

        <!-- Column: Rechnungsdatum -->
        <template #cell(Rechnungsdatum)="data">
          {{
            new Date(data.item.Rechnungsdatum).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          }}
        </template>

        <!-- Column: Fälligkeitsdatum -->
        <template #cell(Faelligkeitsdatum)="data">
          {{
            new Date(data.item.Faelligkeitsdatum).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          }}
        </template>

        <!-- Column: Abrechnungszeitraum-->
        <template #cell(Abrechnungszeitraum)="data">
          {{
            new Date(data.item.Abrechnungszeitraum_Start).toLocaleDateString(
              'de-DE',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )
          }}

          {{
            data.item.Abrechnungszeitraum_Ende
              ? ` - ${new Date(
                  data.item.Abrechnungszeitraum_Ende
                ).toLocaleDateString('de-DE', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}`
              : ''
          }}
        </template>

        <!-- Column: Rechnungsbetrag -->
        <template #cell(Rechnungsbetrag)="data">
          {{
            new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(data.item.Rechnungsbetrag)
          }}
        </template>

        <!-- Column: Status -->
        <template #cell(Status)="data">
          <b-badge :variant="status[1][invoiceStatus(data.item.Status)]">
            {{ status[0][invoiceStatus(data.item.Status)] }}
          </b-badge>
        </template>
      </b-table>
    </div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Zeigt Rechnung {{ dataMeta.from }} bis {{ dataMeta.to }} von
            {{ dataMeta.of }} Rechnungen</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatarGroup,
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BTooltip,
  BMedia,
  BLink,
  BPopover,
  VBTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BAvatarGroup,
    BPagination,
    BFormInput,
    BTooltip,
    BMedia,
    BLink,
    BPopover,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    generalInvoiceTable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      totalRows: 0,
      tableKey: 1,
      orderID: '',
      filters: { searchQuery: '', statusFilter: '' },
      fields: [
        { key: 'Rechnungsnummer', label: 'Rechnungsnummer', sortable: true },
        { key: 'Rechnungsdatum', label: 'Rechnungsdatum', sortable: true },
        { key: 'Faelligkeitsdatum', label: 'Fälligkeitsdatum', sortabl: true },
        {
          key: 'Abrechnungszeitraum',
          label: 'Abrechnungszeitraum',
          sortable: true,
        },
        { key: 'Rechnungsbetrag', label: 'Rechnungsbetrag', sortable: true },
        { key: 'Status', label: 'Status', sortable: true },
        { key: 'Aktionen', label: 'Aktionen' },
      ],
      status: [
        {
          1: 'Entwurf',
          2: 'Offen',
          3: 'Bezahlt',
          4: 'Ueberfaellig',
          5: 'Storniert',
        },
        {
          1: 'secondary',
          2: 'primary',
          3: 'success',
          4: 'danger',
          5: 'dark',
        },
      ],
    };
  },
  computed: {
    dataMeta() {
      const fromItems = this.perPage * (this.currentPage - 1) + 1;
      const toItems = this.perPage * (this.currentPage - 1) + this.perPage;

      return {
        from: fromItems,
        to:
          toItems < this.totalRows
            ? toItems
            : toItems - (toItems - this.totalRows),
        of: this.totalRows,
      };
    },
  },
  watch: {
    perPage(newValue, oldValue) {
      if (newValue !== oldValue) this.refetchTable();
    },
  },
  created() {
    if (this.generalInvoiceTable) {
      this.fields.unshift({ key: 'Kunde', label: 'Kunde', sortable: true });
    }
  },
  methods: {
    async updateStatus(id, status) {
      const data = { Status: status.value };

      await this.$http
        .put(`/rechnungen/${id}`, { data })
        .then(response => {
          this.$root.$emit('bv::hide::popover');
          this.refetchTable();
        })
        .catch(err => {
          console.log(err.response.data.error.message);
        });
    },
    refetchTable() {
      this.tableKey += 1;
    },
    reportingFrequency(term) {
      if (term === 'Taeglich') return 'Täglich';
      if (term === 'Woechentlich') return 'Wöchentlich';
      if (term === 'Monatlich') return 'Monatlich oder bei Auftragsende';
    },
    invoiceStatus(invoice) {
      switch (true) {
        case invoice === 'Entwurf':
          return 1;
        case invoice === 'Offen':
          return 2;
        case invoice === 'Bezahlt':
          return 3;
        case invoice === 'Ueberfaellig':
          return 4;
        case invoice === 'Storniert':
          return 5;
        default:
          return 1;
      }
    },
    getContactPerson(order) {
      const contactPerson = [];
      order.Kunde.Ansprechpartner.forEach(person => {
        if (person.id === order.Ansprechpartner_ID) {
          contactPerson.push(person);
        }
      });
      return contactPerson;
    },
    async invoiceItemsProvider(ctx) {
      switch (true) {
        case ctx.sortBy === 'Abrechnungszeitraum':
          ctx.sortBy = 'Abrechnungszeitraum_Start';
          break;
        case ctx.sortBy === '':
          ctx.sortBy = 'Rechnungsnummer';
          break;
        default:
          break;
      }
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: ctx.currentPage,
            pageSize: this.perPage,
          },
          fields: '*',
          sort: [`${ctx.sortBy}:${ctx.sortDesc ? 'desc' : 'asc'}`],
          filters: {
            Status: {
              $eq:
                ctx.filter.statusFilter === null
                  ? undefined
                  : ctx.filter.statusFilter.value,
            },
            ...(!this.generalInvoiceTable && {
              Auftrag: {
                id: {
                  $eq: this.$route.params.id,
                },
              },
            }),
          },
          populate: {
            Auftrag: {
              fields: ['id', 'Auftragsnummer'],
              populate: {
                Kunde: {
                  fields: ['Firma'],
                },
              },
            },
            Manueller_Kunde: {
              populate: '*',
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let invoiceItems = [];

      await this.$http
        .get(`/rechnungen?${query}`)
        .then(async response => {
          invoiceItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
          if (!this.generalInvoiceTable) {
            await this.$http
              .get(`/auftraege/${this.$route.params.id}?fields=Auftragsnummer`)
              .then(res => {
                this.orderID = res.data.data.Auftragsnummer;
              });
          }
        })
        .catch(err => console.log(err.response.data.error.message));
      return invoiceItems;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#status-selection {
  min-width: 15rem;
}
.per-page-selector {
  min-width: 6rem;
}

.google-map {
  height: 20rem;
  border-radius: 0.5rem;

  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
